import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import Link from "../Link";
import { generateRenewalUrl } from "../../functions/generateRenewalUrl";
import { createCartUrlForSubscription } from "shared-frontend/functions/cart";
import { Button, Modal, Title } from "@yoast/ui-library";
import styles from "./SubscriptionDetailModalStyles.scss";

const messages = defineMessages( {
	paymentSuspended: {
		id: "subscriptionDetail.modal.paymentSuspended",
		defaultMessage: "Unfortunately we were not able to bill your payment method for the next billing cycle. " +
			"This means we have suspend the subscription until the problem is resolved. Sorry for the inconvenience!",
	},
	paymentFailed: {
		id: "subscriptionDetail.header",
		defaultMessage: "Payment Failed",
	},
	manualFix: {
		id: "subscriptionDetail.modal.manualFix",
		defaultMessage: "Whatever the reason may be for the failed payment, you will have to renew your " +
			"subscription manually to fix this. After doing so, the new payment info you provide will be used to " +
			"reactivate your subscription.",
	},
	expiredManualFix: {
		id: "subscriptionDetail.modal.manualFix",
		defaultMessage: "Unfortunately we were not able to bill your payment method for the next billing cycle." +
			" As the failed payment occurred more than a month ago, you will have to buy a new subscription.",
	},
	cancel: {
		id: "subscriptionDetail.modal.cancelButton",
		defaultMessage: "Cancel",
	},
	renew: {
		id: "subscriptionDetail.modal.renewLink",
		defaultMessage: "Renew",
	},
	buy: {
		id: "subscriptionDetail.modal.buyLink",
		defaultMessage: "Buy subscription",
	},
} );

const modalAriaLabel = defineMessages( {
	id: "modal.arialabel.paymentFailed",
	defaultMessage: "Payment failed",
} );

/**
 * Returns the SubscriptionDetailModal.
 *
 * @param {Object} props The props required.
 * @returns { ReactElement } The SubscriptionDetailModal.
 */
const SubscriptionDetailModal = ( props ) => {
	const OneMonthAgo = new Date();
	OneMonthAgo.setMonth( OneMonthAgo.getMonth() - 1 );
	const expiredDate = Math.max( props.subscription.nextPayment?.getTime(), props.subscription.endDate?.getTime() );
	const expiredMoreThanOneMonthAgo = expiredDate < OneMonthAgo.getTime();
	const formattedMessage = <FormattedMessage
		id="subscriptionDetail.modal.help"
		defaultMessage="Let us know if you need any help, { supportLink }!"
		values={ {
			supportLink: <Link to="mailto:support@yoast.com">{
				props.intl.formatMessage( {
					id: "subscriptionDetail.support-link",
					defaultMessage: "our support team is available 24/7",
				} )
			}</Link>,
		} }
	/>;

	return (
		<Modal
			isOpen={ props.modalOpen }
			onClose={ props.onClose }
			aria-label={ modalAriaLabel }
		>
			<Modal.Panel>
				<div className={ styles.modalTitle }>
					<Title as="h2" className={ styles.modalTitle }>
						{ props.intl.formatMessage( messages.paymentFailed ) }
					</Title>
				</div>
				<div className={ styles.modalContent }>
					{ expiredMoreThanOneMonthAgo ? ""
						: <p>
							{ props.intl.formatMessage( messages.paymentSuspended ) }
						</p>
					}
					<p>
						{ expiredMoreThanOneMonthAgo
							? props.intl.formatMessage( messages.expiredManualFix )
							: props.intl.formatMessage( messages.manualFix )
						}
					</p>
					{ expiredMoreThanOneMonthAgo ? ""
						: <p>
							{ formattedMessage }
						</p>
					}
				</div>
				<div className={ styles.modalFooter }>
					<Button
						onClick={ props.onClose }
						variant="secondary"
					>
						{ props.intl.formatMessage( messages.cancel ) }
					</Button>
					<Button
						as="a"
						variant="upsell"
						href={ expiredMoreThanOneMonthAgo
							? createCartUrlForSubscription( props.subscription )
							: generateRenewalUrl( props.subscription ) }
					>
						{ expiredMoreThanOneMonthAgo ? props.intl.formatMessage( messages.buy ) : props.intl.formatMessage( messages.renew ) }
					</Button>
				</div>
			</Modal.Panel>
		</Modal>
	);
};


SubscriptionDetailModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	subscription: PropTypes.object.isRequired,
	modalOpen: PropTypes.bool,
	intl: intlShape.isRequired,
};

SubscriptionDetailModal.defaultProps = {
	modalOpen: false,
};

export default injectIntl( SubscriptionDetailModal );
