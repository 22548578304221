import { Button, Link, Table } from "@yoast/ui-library";
import React, { Fragment } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import { messages } from "./messages";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import SubscriptionDetails from "./SubscriptionDetails";
import formatAmount from "../../../functions/currency";
import { capitalizeFirstLetter } from "shared-frontend/functions/stringHelpers";

const SubscriptionRow = ( props ) => {
	function isActive( status, includePending = true ) {
		const activeStatus = [ "active" ];
		if ( includePending ) {
			activeStatus.push( "pending-cancel" );
		}
		return activeStatus.includes( status );
	}

	function getDetailsLabel() {
		if ( props.needsAttention || props.isInactive ) {
			return props.intl.formatMessage( messages.details );
		}
		return props.intl.formatMessage( messages.nextPaymentOn );
	}

	function getManageButtons( showManageButton, onManage ) {
		if ( ! showManageButton ) {
			return <div className={ styles.styledSpace } />;
		}

		return <Button
			className={ styles.manageButton }
			onClick={ onManage }
			aria-label={ props.intl.formatMessage( messages.manage ) }
		>
			<span className={ styles.buttonText }>{ props.intl.formatMessage( messages.manage ) }</span>
			<span className={ styles.buttonIcon }><ChevronRightIcon /></span>
		</Button>;
	}

	function getNextBilling( status, endDate, nextPayment, amount ) {
		if ( status === "pending-cancel" ) {
			return <FormattedMessage { ...messages.endsDate } values={ { endDate: endDate } } />;
		}

		if ( nextPayment && amount ) {
			return nextPayment;
		}

		return null;
	}

	function getStatus( subscription ) {
		if ( subscription.name.toLowerCase().includes( "shopify" ) && props.subscriptionsArray.length !== 1 ) {
			return "Managed by Shopify";
		}

		if ( ! props.needsAttention ) {
			if ( subscription.status === "pending-cancel" ) {
				return <FormattedMessage { ...messages.pendingCancellation } />;
			}

			return capitalizeFirstLetter( subscription.status );
		}

		if ( subscription.status === "on-hold" ) {
			return <FormattedMessage { ...messages.suspended } />;
		}

		if ( subscription.status === "expired" ) {
			return <FormattedMessage { ...messages.expired } />;
		}

		return <FormattedMessage { ...messages.expiresSoon } />;
	}

	/**
	 * Whether the subscription has a next billing cycle
	 *
	 * @param {object} subscription The subscription object.
	 *
	 * @returns {boolean} Whether the subscription has a next billing cycle.
	 */
	function hasNextBillingCycle( subscription ) {
		return subscription.status === "active" && ( subscription.hasNextPayment || subscription.hasEndDate );
	}

	/**
	 * Get the next payment information for a subscription.
	 *
	 * @param {object} subscription The subscription object.
	 *
	 * @returns {*} The next payment information.
	 */
	function getNextPaymentInformation( subscription ) {
		let endDate = null;
		if ( subscription.status === "pending-cancel" ) {
			endDate = <FormattedDate
				value={ subscription.hasEndDate && subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
			/>;
		}

		let nextPayment = null;
		let amount = null;
		if ( hasNextBillingCycle( subscription ) ) {
			nextPayment = <FormattedDate
				value={ subscription.hasNextPayment ? subscription.nextPayment : subscription.endDate }
				year="numeric"
				month="long"
				day="numeric"
			/>;
			amount = <FormattedNumber
				value={ formatAmount( subscription.billingAmount ) }
				currency={ subscription.billingCurrency }
				style="currency"
			/>;
		}

		return getNextBilling( subscription.status, endDate, nextPayment, amount );
	}

	function getColumnInformation() {
		if ( props.needsAttention || props.isInactive ) {
			return <SubscriptionDetails
				subscription={ props.subscriptionsArray[ 0 ] }
				showDetailsModal={ props.showDetailsModal }
			/>;
		}
		const nextPaymentInformation = getNextPaymentInformation( props.subscriptionsArray[ 0 ] );

		return <>
			{ nextPaymentInformation }
			{ props.subscriptionsArray[ 0 ].billingType }
		</>;
	}

	const subscription = props.subscriptionsArray[ 0 ];

	let billingType = null;
	if ( hasNextBillingCycle( subscription ) ) {
		billingType = subscription.requiresManualRenewal ? "Manual renewal" : "Automatic renewal";
	}

	const status = getStatus( subscription );

	/**
	 * Get the default columns for the subscription row.
	 *
	 * @returns {Element} The default columns for the subscription row.
	 */
	function getDefaultColumns() {
		return <>
			<Table.Cell className={styles.tableUsage}>
				<div className={ styles.activeSites } key={ subscription.id + "-sites" }>
					{ subscription.hasSites && isActive( subscription.status )
						? subscription.used + "/" + subscription.limit + " sites"
						: ""
					}
				</div>
			</Table.Cell>
			<Table.Cell className={styles.tableRenewal}>
				<div key={ subscription.id + "-billingtype" }>
					{ billingType }
				</div>
			</Table.Cell>
			<Table.Cell className={styles.tableBilling}>
				<div
					key={ subscription.id + "-details" }
					aria-label={ getDetailsLabel() }
				>
					{ getColumnInformation() }
				</div>
			</Table.Cell>
			<Table.Cell>
				<div>
					{ ( props.needsAttention || props.isInactive ) && ! props.isProvisioned
						? getManageButtons( subscription.showManageButton, () => props.showDetailsModal( subscription ) )
						: getManageButtons( subscription.showManageButton, () => props.onManage( subscription.id ) )
					}
				</div>
			</Table.Cell>
		</>;
	}

	/**
	 * Add the columns for the partner subscriptions table to the subscription row.
	 *
	 * @returns {Element} The columns for the partner subscriptions table.
	 */
	function getPartnerColumns() {
		const provisionerData = props.provisionerData[ subscription.provisionerId ];

		return (
			<Table.Cell>
				<div>
					{ provisionerData &&
						<Link href={ provisionerData.customerPlatformUrl } target="_blank">
							{ props.intl.formatMessage( messages.gotoProvisioner, { name: provisionerData.displayName } ) }
						</Link>
					}
				</div>
			</Table.Cell>
		)
		;
	}

	return <Table.Row
		className={ props.isPartOfCollapsible ? styles.subscriptionPartOfCollapsible : styles.subscription }
	>
		<Table.Cell className={ styles.general }>
			<div className={ styles.subscriptionIcon }>
				<img src={ subscription.icon } alt="" />
			</div>
			<div className={ styles.primary }>
				<span className={ props.isPartOfCollapsible ? styles.hide : "" }> { subscription.name } </span>
				<div>
					<span>
						{ status }
					</span>
					{ subscription.subscriptionNumber && ` - ${subscription.subscriptionNumber}` }
				</div>
			</div>
		</Table.Cell>
		{ props.isProvisioned ? getPartnerColumns() : getDefaultColumns() }
	</Table.Row>;
};

SubscriptionRow.propTypes = {
	onManage: PropTypes.func.isRequired,
	subscriptionsArray: PropTypes.array.isRequired,
	provisionerData: PropTypes.object,
	intl: intlShape.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
	isPartOfCollapsible: PropTypes.bool,
};

SubscriptionRow.defaultProps = {
	provisionerData: {},
	showDetailsModal: null,
	isProvisioned: false,
	needsAttention: false,
	isInactive: false,
	isPartOfCollapsible: false,
};

export default injectIntl( SubscriptionRow );
